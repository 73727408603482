/*===== VARIABLES CSS =====*/

/* Font Size */
:root {
  --normalFont: 16px;
}

/* Colors */
:root {
  --white: #ffffff;
  --text: #fff;
}

/* Fonts */
:root {
  --primaryFont: "Montserrat", sans-serif;
  --stylishFont: "Tahu";
}

/* Margins */
:root {
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
}

/*===== z index =====*/
:root {
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*===== BASE =====*/
*,
::before,
::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
p {
  margin: 0 !important;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

section {
  display: flex;
  width: 90%;
  padding: 2rem 0;
}

body {
  font-family: var(--primaryFont) !important;
  font-size: var(--normalFont);
  color: var(--text) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #090c10 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-icons {
  font-size: 5rem !important;
}
