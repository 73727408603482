.outer_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: var(--mb-3) 0;
}

.wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
}

.image_container {
  display: flex;
  align-items: center;
}

.image_container > img {
  width: 250px;
  border-radius: 20px;
}

.text_container {
  display: flex;
  flex-direction: column;
}

.text_container > div {
  display: flex;
  align-items: center;
  margin: var(--mb-1) 0;
}

.name_textbox {
  margin-bottom: var(--mb-1) !important;
  text-align: center;
}

.icon_box {
  margin-right: var(--mb-1);
  display: flex;
  align-items: center;
}

.address_textbox {
  display: flex;
  align-items: flex-start;
}

.construction_wrapper {
  margin: 0 var(--mb-2);
  margin-top: var(--mb-6);
  margin-bottom: var(--mb-2);
  display: flex;
  align-items: center;
  color: #cc3300;
  font-weight: 500;
  font-size: 1.3rem;
}

.construction_wrapper > p {
  text-align: center;
}

/* Media Queries */

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
  }

  .text_container {
    margin-top: var(--mb-2);
  }

  .text_container > div {
    justify-content: center;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
  }

  .text_container {
    margin-top: var(--mb-2);
  }

  .text_container > div {
    justify-content: center;
  }

  .construction_wrapper {
    margin-top: var(--mb-3);
  }
}
